import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import React, { useState, useEffect, useRef, Suspense, lazy, useMemo } from 'react';
import './App.css'; // Import your CSS file for the modal styles
import { handleRefresh } from './RefreshUtils';
import Connecto from './Connecto';


const AvatarMenuLazy = lazy(() => import('./avatarMenu'));
const AvatarLazy = lazy(() => import('./avatar'));
const CurrencyToggleLazy = lazy(() => import('./CurrencyToggle'));
const UserProfileLinkLazy = lazy(() => import('./UserProfileLink'));
const LanguageSelectorLazy = lazy(() => import('./LanguageSelector'));
const LogoutLazy = lazy(() => import('./Logout'));


interface MainMenuProps {
  setActiveSection: (section: string) => void;
  setWhatPage: (page: string) => void;
  currentURLState: number; 
  setCurrentURLState: (state: number) => void; 
}

const MainMenu: React.FC<MainMenuProps> = ({ setActiveSection, setWhatPage, currentURLState, setCurrentURLState }) => {

   
  const { t } = useTranslation();


  const [showModal, setShowModal] = useState(false);
  const modalRefMenu = useRef<HTMLDivElement>(null);
  const [wallet, setWallet] = useState<string | undefined>(undefined);
  const [hasWallet, setHasWallet] = useState(false);
  const [shouldInitializeWallet, setShouldInitializeWallet] = useState(false);
  const [isVisitor, setIsVisitor] = useState(true);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRefMenu.current && !modalRefMenu.current.contains(event.target as Node)) {
      setShowModal(false);
    }
  };

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`) 
    ? `https://${stagingKey}.peaxel.me` 
    : 'https://peaxel.me';

  const [indexLanguage, setIndexLanguage] = useState('en');

  const isMobile = window.innerWidth <= 767;

  useEffect(() => {
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en';
    setIndexLanguage(storedLanguage);
  }, []);

  const handleLinkClick = (page:any, section:any) => {
    const newUrl = `${linkUrl}/game/?page=${encodeURIComponent(page)}&section=${encodeURIComponent(section)}`;
    window.history.pushState({}, '', newUrl); // Update the URL
    localStorage.setItem('pxlPage',page);
    localStorage.setItem('pxlSection',section);
    setCurrentURLState(currentURLState +1);
  };

  useEffect(() => {
    if (shouldInitializeWallet) {
      const savedWalletAddress = localStorage.getItem('walletAddress');
      if (savedWalletAddress) {
        setWallet(savedWalletAddress);
        setHasWallet(savedWalletAddress.length > 8);
      }
    }
  }, [shouldInitializeWallet]);

  useEffect(() => {
    setShouldInitializeWallet(true);
  }, []);

  useEffect(() => {
    const getVisitor = localStorage.getItem('walletAddress');
    if (getVisitor === '0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a') {
      setIsVisitor(true);
    } else {
      setIsVisitor(false);
    }
  }, []);


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  const classNameConnect = hasWallet ? 'Show' : 'noShow';
  const classNameNoConnect = hasWallet ? 'noShow' : 'Show';

  return (
    <div>
      <span onClick={() => setShowModal(true)}>
        <span className={classNameConnect}>
          <span className="BigButton">
            <Suspense fallback={<div>{t('loading')}</div>}>
              <AvatarLazy />
            </Suspense>
          </span>
        </span>
      </span>

      {showModal && (
        <div className="modal-container-big" ref={modalRefMenu}>

            <button 
              className="close-button-modal" 
              onClick={() => setShowModal(false)}>
             <i className="fa-regular fa-circle-xmark"></i>
            </button>

            
          <div className={classNameConnect}>
            <Suspense fallback={<div>{t('loading')}</div>}>
              <AvatarMenuLazy />
            </Suspense>
          </div>

          

          <div className="menuList">
            <table className="table_menu_all">
              <tr>
                <td className="table_menu_C1">
                  <span
                    onClick={() => handleLinkClick('dashboard', '')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-solid fa-chart-simple"></i>
                  </span>
                </td>
                <td className="table_menu">
                  <span
                    onClick={() => handleLinkClick('dashboard', '')}
                  >
                    {t('dashBoard')}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="table_menu_C1">
                  <span
                    onClick={() => handleLinkClick('market', 'allTalents')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-solid fa-shield-halved"></i>
                  </span>
                </td>
                <td className="table_menu">
                  <span
                    onClick={() => handleLinkClick('market', 'allTalents')}
                  >
                    {t('market')}
                  </span>
                </td>
              </tr>


            {isMobile && (
              <tr>
                <td className="table_menu_C1_m">
                  <span
                    onClick={() => handleLinkClick('market', 'allTalents')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-regular fa-address-card"></i>
                  </span>
                </td>
                <td className="table_menu_m">
                  <span
                    onClick={() => handleLinkClick('market', 'allTalents')}
                  >
                    {t('allTalents')}
                  </span>
                </td>
              </tr>
            )}


{isMobile && (
              <tr>
                <td className="table_menu_C1_m">
                  <span
                    onClick={() => handleLinkClick('market', 'allAuctions')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-regular fa-id-badge"></i>
                  </span>
                </td>
                <td className="table_menu_m">
                  <span
                    onClick={() => handleLinkClick('market', 'allAuctions')}
                  >
                    {t('allAuctions')}
                  </span>
                </td>
              </tr>
)}

{isMobile && (
              <tr>
                <td className="table_menu_C1_m">
                  <span
                    onClick={() => handleLinkClick('market', 'managersSales')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-regular fa-id-badge"></i>
                  </span>
                </td>
                <td className="table_menu_m">
                  <span
                    onClick={() => handleLinkClick('market', 'managersSales')}
                  >
                    {t('managersSales')}
                  </span>
                </td>
              </tr>
)}

{isMobile && (
              <tr>
                <td className="table_menu_C1_m">
                  <span
                    onClick={() => handleLinkClick('market', 'offersReceived')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-solid fa-right-to-bracket"></i>
                  </span>
                </td>
                <td className="table_menu_m">
                  <span
                    onClick={() => handleLinkClick('market', 'offersReceived')}
                  >
                    {t('offersReceived')}
                  </span>
                </td>
              </tr>
)}

              {isMobile && (
              <tr>
                <td className="table_menu_C1_m">
                  <span
                    onClick={() => handleLinkClick('market', 'offersSent')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-solid fa-right-from-bracket"></i>
                  </span>
                </td>
                <td className="table_menu_m">
                  <span
                    onClick={() => handleLinkClick('market', 'offersSent')}
                  >
                    {t('offersSent')}
                  </span>
                </td>
              </tr>
)}



{isMobile && (
              <tr>
                <td className="table_menu_C1_m">
                  <span
                    onClick={() => handleLinkClick('market', 'listedTalents')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-solid fa-share-from-square"></i>
                  </span>
                </td>
                <td className="table_menu_m">
                  <span
                    onClick={() => handleLinkClick('market', 'listedTalents')}
                  >
                    {t('listedCards')}
                  </span>
                </td>
              </tr>
)}



              <tr className={classNameConnect}>
                <td className="table_menu_C1">
                  <span
                    onClick={() => handleLinkClick('team', 'overView')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-solid fa-user-group"></i>
                  </span>
                </td>
                <td className="table_menu">
                  <span
                    onClick={() => handleLinkClick('team', 'overView')}
                  >
                      {t('team')}
                  </span>
                </td>
              </tr>


              {isMobile && (
              <tr className={classNameConnect}>
                <td className="table_menu_C1_m">
                  <span
                    onClick={() => handleLinkClick('team', 'overView')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-solid fa-list-check"></i>
                  </span>
                </td>
                <td className="table_menu_m">
                  <span
                    onClick={() => handleLinkClick('team', 'overView')}
                  >
                    {t('overview')}
                  </span>
                </td>
              </tr>
)}


{isMobile && (
              <tr className={classNameConnect}>
                <td className="table_menu_C1_m">
                  <span
                    onClick={() => handleLinkClick('team', 'myLineup')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-solid fa-bolt-lightning"></i>
                  </span>
                </td>
                <td className="table_menu_m">
                  <span
                    onClick={() => handleLinkClick('team', 'myLineup')}
                  >
                    {t('myLineup')}
                  </span>
                </td>
              </tr>
)}


              {isMobile && (
              <tr className={classNameConnect}>
                <td className="table_menu_C1_m">
                  <span
                    onClick={() => handleLinkClick('team', 'allMyTalents')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-solid fa-address-card"></i>
                  </span>
                </td>
                <td className="table_menu_m">
                  <span
                    onClick={() => handleLinkClick('team', 'allMyTalents')}
                  >
                    {t('allMyTalents')}
                  </span>
                </td>
              </tr>
)}


{isMobile && (
              <tr className={classNameConnect}>
                <td className="table_menu_C1_m">
                  <span
                    onClick={() => handleLinkClick('team', 'allMyCards')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-solid fa-id-badge"></i>
                  </span>
                </td>
                <td className="table_menu_m">
                  <span
                    onClick={() => handleLinkClick('team', 'allMyCards')}
                  >
                    {t('allMyCards')}
                  </span>
                </td>
              </tr>
             
             )}
              



              <tr>
                <td className="table_menu_C1">
                  <span
                    onClick={() => handleLinkClick('competition', '')}
                  >
                    <i style={{ opacity: 0.5 }} className="fa-solid fa-bolt-lightning"></i>
                  </span>
                </td>
                <td className="table_menu">
                  <span
                    onClick={() => handleLinkClick('competition', '')}
                  >
                    {t('competition')}
                  </span>
                </td>
              </tr>

{(isVisitor===false) && (            
              <tr className={classNameConnect}>
                <td className="table_menu_C1">
                  <i style={{ opacity: 0.5 }} className="fa-solid fa-right-from-bracket"></i>
                </td>


                <td className="table_menu">
                  <Suspense fallback={<div>{t('loading')}</div>}>
                    <LogoutLazy />
                  </Suspense>
                </td>
              </tr>
)}

            

            </table>
          



          <ul className="menu-items">
            
            
            <li>
              <div className={classNameConnect}>
                <span className="menu-line">_____________________</span>
                <br />
              </div>
            </li>


            <li>
                <Suspense fallback={<div>{t('loading')}</div>}>
                <CurrencyToggleLazy />&nbsp;&nbsp;<LanguageSelectorLazy />
              </Suspense>
            </li>
            <li>
              
              
              
              <div className={classNameConnect}>
                <span className="menu-line">_____________________</span>
                <br />
                <br />
              </div>
            </li>



           

          </ul>

          </div>



        </div>
      )}
    </div>
  );
};

export default MainMenu;
